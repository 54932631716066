import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Reveal from '../components/reveal'

const NotFoundPage = ({ data, location }) => {
    const siteTitle = data.site.siteMetadata.title

    return (
        <Layout location={location} title="404: Not Found" darkerHeader="1">
            <section className="section section-hero">
                <Reveal className="wrap">
                    <h1 className="label">404</h1>
                    <h2 className="title">Not Found</h2>
                    <div className="container">
                        <p>
                            You just hit a route that doesn&#39;t exist... the
                            sadness.
                        </p>
                    </div>
                </Reveal>
            </section>
        </Layout>
    )
}

export default NotFoundPage

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
`
